import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image from "./Illuminate Web Assets/The Challenge/Challenege-landing.png";

// const WebinarCard = ({ webinar }) => (
//   <div className="webinar-card">
//     <h3 className="webinar-title">{webinar.title}</h3>
//     <p className="webinar-date">Date: {webinar.date}</p>
//     <p className="webinar-description">Description:</p>
//     <p className="webinar-description">{webinar.description}</p>
//   </div>
// );

const Challenge = () => {
  // const webinarDetails = [
  //   {
  //     title: "Project Ideation & Brainstorming",
  //     date: "8/30/2023",
  //     description:
  //       "Gain insights into strategies and techniques for generating and refining innovative ideas. Explore various brainstorming methods and learn how to overcome common challenges. Elevate your project development skills and boost your creative thinking abilities.",
  //   },
  //   {
  //     title: "What is a Proposal?",
  //     date: "9/13/2023",
  //     description:
  //       "Gain a comprehensive understanding of the purpose, structure, and components of a compelling proposal. Learn expert tips on crafting persuasive arguments, defining goals, and showcasing your expertise.",
  //   },
  //   {
  //     title: "Technical Writing",
  //     date: "9/27/2023",
  //     description:
  //       "Enhance your communication skills and learn how to convey complex information clearly and concisely. Discover industry best practices, essential tools, and writing techniques to produce professional technical documents.",
  //   },
  //   {
  //     title: "Software to Use",
  //     date: "10/22/2023",
  //     description:
  //       "Learn and utilize a variety of software to help you develop your proposed solution. Here you’ll learn some of our favorite software that we use in the Luminosity Lab and some great free alternatives to industry standards.",
  //   },
  //   {
  //     title: "Pitch VS Presentation",
  //     date: "11/8/2023",
  //     description:
  //       "After finalizing your proposal, gain insights on crafting compelling presentations and acing the Q&A session. Master the art of engagement and persuasion.",
  //   },
  //   {
  //     title: "Dos & Dont's",
  //     date: "11/15/2023",
  //     description:
  //       "Prepare for the upcoming Pitch event! Discover essential presentation do's and don'ts to captivate your audience effectively and properly.",
  //   },
  // ];

  return (
    <div className="home">
      <div className="image-container">
        <img src={image} alt="Illustration of upcoming event" />
      </div>
      <div className="text-container">
        <div className="title-black">
          <p>Prompt</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
            What does your community produce and consume, and how can your
            community improve its systems of production and consumption? What is
            your community doing to reduce waste, and how can you support them?
            How would you ensure responsible consumption and production in your
            daily life, and how can you encourage and support others in your
            community to do the same?
          </p>
          <p>
            These are just a few questions to consider around the{" "}
            <a href="https://sdgs.un.org/goals/goal12#overview">
              UN’s Sustainable Development Goal (SDG) #12: Responsible
              Consumption and Production
            </a>
            . Assemble a team to respond to these questions, or one of your own,
            with a product you can build and implement to improve your
            community. Register your team with the Luminosity Lab’s Illuminate
            Competition for the opportunity to present and build your idea, and
            begin improving the world around you!
          </p>
        </div>
      </div>
      <div className="spacer"></div>
      <div className="text-container">
        <div className="h2-black">
          <p>Overview</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
            Your team will be tasked with creating an innovative and novel idea
            in response to the prompt above. You will be required to submit
            deliverables and will be competing with other teams for a spot in
            the finals. There are three phases for this competition: Proposal
            Stage, Pitch Stage, Build Stage. The first semester will include the
            Proposal Stage and the Pitch Stage. After pitching your idea at the
            Luminosity Expo, teams will be selected to move onto the Build
            Stage.
          </p>
          <p>
            During the competition, webinars and office hours will be available
            to assist you in developing your idea. The dates will be announced
            soon! Registration opens August 1, 2024!
          </p>
        </div>
      </div>
      <div className="spacer"></div>
      <div className="text-container">
        <div className="h2-black">
          <p>Deliverables</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
            For the first semester of the school year, you will be required to
            submit two deliverables: a written proposal and a video
            presentation. The written proposal can be no longer than 10 pages
            addressing the prompt. The video presentation is a 1-2 minute video
            going over the problem statement and a high level overview of the
            proposed solution. For more details, please refer to the student
            packet.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="h2-black">
          <p>Criteria</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p> - Teams of 4 students or less </p>
          <p> - Everyone must be a high school student</p>
          <p>
            - Every team must have a mentor/supervisor (teacher, parent,
            administrator, etc.)
            <p>
              - Each team must have a community partner or industry
              professional.
            </p>
            {/* - Must be registered by 10/06/2023 */}
          </p>
          <p> - Must fill out all required forms</p>
        </div>
      </div>
      {/* <div className="text-container">
        <div>
          <a
            className="button-maroon"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfnjS5GVH8lFSWF_shv7q1JpCoN_9ZCpIfsCCGrSqyF3P9Gyw/viewform?pli=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register
          </a>
        </div>
        <div className="text-container">
          <a
            className="button-white"
            href="https://docs.google.com/document/d/17LJkxry3omfnFw2yT2CJhrYmBbeRPYSWKInQC-aNs60/edit?usp=sharing "
            target="_blank"
            rel="noopener noreferrer"
          >
            Student Packet
          </a>
          <a
            className="button-white"
            href="https://docs.google.com/document/d/14MNRGB9kK8ZfGA8AwRBUdoeo940ZCORDFKjAKUT1Suc/edit?usp=drive_link "
            target="_blank"
            rel="noopener noreferrer"
          >
            Mentor Packet
          </a>
        </div>
      </div> */}
      <div className="text-container">
        <div className="h2-black">
          <p>Prizes</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>Will be announced soon!</p>
        </div>
      </div>
      {/* <div className="text-container">
        <div className="h2-black">
          <p>Webinars</p>
        </div>
      </div>
      <div className="card-container">
        {webinarDetails.map((webinar, index) => (
          <WebinarCard key={index} webinar={webinar} />
        ))}
      </div> */}
      <div className="text-container">
        <div className="h2-black">
          <p>Calendar</p>
        </div>
      </div>
      <div>
        <iframe
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FPhoenix&src=aWxsdW1pbmF0ZS5sdW1pbm9zaXR5QGdtYWlsLmNvbQ&src=ODM4N2JhMmY4NjJlYzU2N2I4Mjc2MWE5ZjcyYmI5ZjRlMjFhNWE5YzRhZGZlM2E5YzU3YjA3OTA2N2Q1NWM0YUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%23F09300&color=%230B8043"
          width="100%"
          height="600"
          title="Google Calendar"
        ></iframe>
      </div>
    </div>
  );
};

export default Challenge;
