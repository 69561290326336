import React from "react";
import image from "./Illuminate Web Assets/Past Winners/Past-winners-landing.jpg";
import firstPlace from "./Illuminate Web Assets/Past Winners/Hunger Hunters.jpg";
import secondPlace from "./Illuminate Web Assets/Past Winners/EcoSeal.jpg";
import thirdPlace from "./Illuminate Web Assets/Past Winners/Power-Against-Hunger.jpg";

const Past = () => {
  return (
    <div className="home">
      <div className="image-container">
        <img src={image} alt="Illustration of upcoming event" />
      </div>
      <div className="text-container">
        <div className="title-black">
          <p>Congrat's to our 2023 Winners!</p>
        </div>
      </div>
      <div className="spacer"></div>
      <div className="text-container">
        <div className="h2-black">
          <p>Prompt</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
            How can food be better distributed in your community? Who is in need
            of food who doesn’t have it? In what ways can food waste be
            decreased? Here are just a few questions to spark inspiration around
            the UN’s Sustainable Development Goal (SDG) #2: Zero Hunger.
            Research one of these questions, or one of your own, and design a
            product, system, or service that will contribute to reducing hunger
            in your community or around the world. Submit a proposal by February
            15, 2023, to be considered for the opportunity to pitch your idea at
            the Luminosity Lab’s Expo in March!
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="h2-black">
          <p>Last Year's Winners</p>
        </div>
      </div>
      <div className="winners-container">
        <div className="winner-card">
          <img src={firstPlace} alt="Winner 1" />
          <p className="winner-name">1st Place: Hunger Hunters</p>
          <p className="winner-info">
            Members: Macy Abraham, Gosimuzo Alilonu, Aubrey Clark
          </p>
          <p className="winner-info">Prize: Ender Creality 3D Printers</p>
          <div className="text-container">
          <a
              className="button-white"
              href={require("./Illuminate Web Assets/Past Winners/Hunger Hunters - Proposal.pdf")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Proposal
            </a>
            <a
              className="button-white"
              href={require("./Illuminate Web Assets/Past Winners/Hunger Hunters - Pitch.pdf")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Pitch
            </a>
          </div>
        </div>
        <div className="winner-card">
          <img src={secondPlace} alt="Winner 2" />
          <p className="winner-name">2nd Place: EcoSeal</p>
          <p className="winner-info">
            Members: Noah Hing, Liam Stange, Alex Huang, and Prisha Shroff
          </p>
          <p className="winner-info">Prize: Sphero Coding Robot</p>
          <div className="text-container">
            <a
              className="button-white"
              href={require("./Illuminate Web Assets/Past Winners/EcoSeal - Proposal.pdf")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Proposal
            </a>
            <a
              className="button-white"
              href={require("./Illuminate Web Assets/Past Winners/EcoSeal - Pitch.pdf")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Pitch
            </a>
          </div>
        </div>
        <div className="winner-card">
          <img src={thirdPlace} alt="Winner 3" />
          <p className="winner-name">3rd Place: Power Against Hunger</p>
          <p className="winner-info">
            Members: Xochitl Felix, Tatum Zerbib, Jaya Vijungco, and Maryel
            Rivera
          </p>
          <p className="winner-info">Prize: Ender Creality 3D Printers</p>
          <div className="text-container">
            <a
              className="button-white"
              href={require("./Illuminate Web Assets/Past Winners/Power Against Hunger - Proposal.pdf")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Proposal
            </a>
            <a
              className="button-white"
              href={require("./Illuminate Web Assets/Past Winners/Power Against Hunger - Pitch.pdf")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Pitch
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Past;
