import React from "react";

const Submission = () => {
  return (
    <div className="home">
      <div className="text-container">
        <div className="title-black">
          <p>Submission</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
            Please create a team email through Google that all members of your
            team have access to. You will be using your team Google account to
            submit deliverables. Below you will information and submission for
            the deliverables.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="card">
          <h2>Proposal and Submission</h2>
          <p>10/20/2023</p>
          <p>
            Description: Create a written proposal addressing the prompt. It can
            be no longer than 10 pages in either MLA or APA format. For more
            details on the outline please refer to the student packet.
          </p>
          <p>
            Also, create a 1-2 minute video going over the problem statement and
            a high level overview of the proposed solution. It is recommended to
            use iMovie for simplicity. For more details on the outline please
            refer to the student packet.
          </p>
          <div className="text-container">
        <div>
          <a
            className="button-maroon"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdUULKvyfE5kg9vdy5wDW0eZ6qj_dIPlQPryoW2XamKnm6BCg/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            Submit
          </a>
        </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Submission;
