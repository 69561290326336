import React from "react";
import image from ".//Illuminate Web Assets/About us/Luminosity-team.png";
import image2 from ".//Illuminate Web Assets/About us/lumo-logo-Gold .png";
import image3 from ".//Illuminate Web Assets/About us/Vanderploeg-scholar-team.png";


const About = () => {
  return (
    <div className="home">
      <div className="image-container">
        <img src={image} alt="Illustration of upcoming event" />
      </div>
      <div className="text-container">
        <div className="title-black">
          <p>The Luminosity Lab</p>
        </div>
      </div>
      <div className="content-container">
        <div className="image-container">
          <img src={image2} alt="Illustration of upcoming event" />
        </div>
        <div className="text-container">
          <div className="text-b1">
            <p>
              The Luminosity Lab at Arizona State University is an energetic hub
              of innovation and creativity. It's a dynamic research initiative
              where brilliant minds come together to tackle big challenges and
              find groundbreaking solutions. Led by passionate students,
              faculty, and industry partners.
            </p>
            <p>
              At its core, the Luminosity Lab is all about making a positive
              impact on society. Students get hands-on experience, diving
              headfirst into real-world projects that matter and working with
              external organizations and government agencies. They unleash their
              imaginations, employing design thinking and entrepreneurship to
              create game-changing innovations.
            </p>
          </div>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          Learn more about our past and current projects at the official
          Luminosity Lab website!
        </div>
      </div>
      <div className="text-container">
        <div>
          <a
            className="button-maroon"
            href="https://theluminositylab.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More
          </a>
        </div>
      </div>
      <div className="image-container">
        <img src={image3} alt="Illustration of upcoming event" />
      </div>
      <div className="text-container">
        <div className="title-black">
          <p>Vanderplog Luminosity Scholarship Program</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
          The Vanderploeg Luminosity Scholarship Program is an extension of The Luminosity Lab’s innovative learning and research model, aimed at providing incoming freshman and sophomores students with the opportunity to engage in real world innovation through systems thinking and rapid product realization during their time at ASU.
          </p>
          <p>
          This scholarship is for students interested in engaging in applied learning opportunities while pursuing their undergraduate degree. Scholars will not only receive a monetary award but will participate in weekly programing that provides a holistic view of the technical, managerial, financial, analytical, and human perspectives that are needed to successfully design and implement complex solutions to solving 21st century challenges.
          </p>
          <p>
          Scholarship applications will be open in early October. Selected semifinalists will be interviewed throughout the month of April. Following the interviews, a group of the semifinalists will be offered an opportunity to join the Vanderploeg Luminosity Scholars Program. Award recipients will be announced in the month of May.
          </p>
          <p>Learn more about the scholars and project on the official Luminosity website!</p>
        </div>
      </div>
      <div className="text-container">
        <div>
          <a
            className="button-maroon"
            href="https://scholarships.asu.edu/scholarship/vanderploegluminosity "
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
