import React from "react";

const Challenge = () => {
  return (
    <div className="home">
      <div className="text-container">
        <div className="title-black">
          <p>Meet the Judges</p>
        </div>
      </div>
      <div className="text-container">
        <div className="title-black">
          <p>Community Judge</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>Community Role - Company</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
            The judge is a legal professional with a community-centered
            background. With a deep-rooted empathy and firsthand understanding
            of their community's challenges, The judge brings a compassionate
            and fair approach to the bench. Actively engaged with local
            organizations and residents, they prioritize equal access to justice
            and advocate for positive change.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="title-black">
          <p>Industry Judge</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>Industry Role - Company</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
            The judge is a distinguished legal professional with a
            construction/architecture-centered background. With a profound
            understanding of the industry's intricacies, The judge brings a
            unique perspective to the bench. Their expertise ensures fair and
            informed decisions, promoting the highest standards of construction
            law and advocating for sustainable and innovative practices.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="title-black">
          <p>Jasmine Amoako-Agyei</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>Business Sustainability - Luminosity Staff</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
            Jasmine, a social entrepreneur and Tillman Scholar, co-authored
            children's books and holds degrees in Marketing, Sustainability, and
            Technological Entrepreneurship & Management. Through her UN
            Millennium Fellowship, she founded Countdown: Circular Economy
            Solutions, a community-centered startup addressing plastic pollution
            in Ghana and at ASU. Jasmine leads creative direction, project
            management, and strategic partnerships with various organizations.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="title-black">
          <p>Lukah Seyler</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>ASU Engineering Research Student</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
            Lukah, a second-year Aerospace Engineering student at Arizona State
            University, developed a bike lock prototype to combat campus bike
            theft during his time as a Vanderploeg scholar. Passionate about
            space exploration, he aims to design and construct rockets.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="title-black">
          <p>Luminosity Staff</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>Occupation - Luminosity Staff</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
            Another talented member on the Luminosity team will be a judge for
            this competition!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Challenge;
