import React from "react";
import Countdown from "react-countdown";
import image from ".//Illuminate Web Assets/Home/Home-landing.jpg";
import image2 from ".//Illuminate Web Assets/Home/Home-infrastructure-concept 2.png";
// import challengeLogo from ".//Illuminate Web Assets/Home/Illuminate Challenge Iconography-03.png";
// import registerLogo from ".//Illuminate Web Assets/Home/Illuminate Challenge Iconography-04.png";
// import submissionLogo from ".//Illuminate Web Assets/Home/Illuminate Challenge Iconography-07.png";
// import judgesLogo from ".//Illuminate Web Assets/Home/Illuminate Challenge Iconography-05.png";
// import aboutLogo from ".//Illuminate Web Assets/Home/Illuminate Challenge Iconography-02.png";
// import contactLogo from ".//Illuminate Web Assets/Home/Illuminate Challenge Iconography-08.png";

const Home = () => {
  const targetDate = new Date("08/01/2024 00:00:00");

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="home">
        <div className="image-container">
          <img src={image} alt="Illustration of upcoming event" />
        </div>
        <div className="countdown-timer-container">
          <div className="countdown-timer-container">
            <div className="countdown-timer">
              <div className="countdown-group">
                <p className="countdown-number">{days}</p>
                <p className="countdown-label">&nbsp;days</p>
              </div>
              <div className="countdown-group">
                <p className="countdown-number">{hours}</p>
                <p className="countdown-label">&nbsp;hours</p>
              </div>
              <div className="countdown-group">
                <p className="countdown-number">{minutes}</p>
                <p className="countdown-label">&nbsp;minutes</p>
              </div>
              <div className="countdown-group">
                <p className="countdown-number">{seconds}</p>
                <p className="countdown-label">&nbsp;seconds</p>
              </div>
            </div>
          </div>
          <div className="title-maroon">
            <p>Registration Opens</p>
            <div className="h2-maroon-home">
              <p>08/01/2024</p>
            </div>
          </div>
        </div>

        <div className="text-container">
          <div className="title-black">
            <p>Imagine. Create. Succeed.</p>
          </div>
          <div className="spacer"></div>
          <div className="title-maroon">
            <p> Turn Dreams into Reality!</p>
          </div>
        </div>
        <div className="text-container">
          <div className="text-b1">
            <p>
              Welcome to the Illuminate Challenge! This year long competition is
              designed to help empower and inspire Arizona high school students
              to tackle the global Sustainable Development Goals (SDGs) while
              providing them with valuable experience, mentorship, connections,
              and prizes.
            </p>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="text-container">
          <div className="h2-black">
            <p>This Year's</p>
          </div>
          <div className="spacer"></div>
          <div className="h2-maroon">
            <p>Prompt</p>
          </div>
        </div>
        <div className="text-container">
          <div className="text-b1">
            <p>
              What does your community produce and consume, and how can your
              community improve its systems of production and consumption? What
              is your community doing to reduce waste, and how can you support
              them? How would you ensure responsible consumption and production
              in your daily life, and how can you encourage and support others
              in your community to do the same?
            </p>
            <p>
              These are just a few questions to consider around the{" "}
              <a href="https://sdgs.un.org/goals/goal12#overview">
                UN’s Sustainable Development Goal (SDG) #12: Responsible
                Consumption and Production
              </a>
              . Assemble a team to respond to these questions, or one of your
              own, with a product you can build and implement to improve your
              community. Register your team with the Luminosity Lab’s Illuminate
              Competition for the opportunity to present and build your idea,
              and begin improving the world around you!
            </p>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="text-container">
          <div className="h2-black">
            <p>Who Can</p>
          </div>
          <div className="spacer"></div>
          <div className="h2-maroon">
            <p>Join?</p>
          </div>
        </div>
        <div className="content-container">
          <div className="image-container">
            <img src={image2} alt="Illustration of upcoming event" />
          </div>
          <div className="text-container">
            <div className="text-b1">
              <p>Anyone that is currently in high school can register. </p>
              <p>
                First, you’ll need a team (1-4 members) and a mentor, either a
                teacher, parent, administrator, etc.
              </p>
              <p>
                Second, register your newly found team for the Illuminate
                Challenge! You can find more details under the registration
                page.
              </p>
              <p>
                Third, time to ideate! Brainstorm a solution tackling this
                year’s prompt.
              </p>
              <p>
                Fourth, prepare a proposal that your team will submit and
                present in front of a panel of judges in December!
              </p>
              {/* <div className="links-container">
                <a className="link-card" href="/challenge">
                  <img
                    className="logo-image"
                    src={challengeLogo}
                    alt="Challenge"
                  />
                  The Challenge
                </a>
                <a className="link-card" href="/registration">
                  <img
                    className="logo-image"
                    src={registerLogo}
                    alt="Challenge"
                  />
                  Registration
                </a>
                <a className="link-card" href="/submission">
                  <img
                    className="logo-image"
                    src={submissionLogo}
                    alt="Challenge"
                  />
                  Submission
                </a>
                <a className="link-card" href="/judges">
                  <img
                    className="logo-image"
                    src={judgesLogo}
                    alt="Challenge"
                  />
                  Judges
                </a>
                <a className="link-card" href="/about">
                  <img className="logo-image" src={aboutLogo} alt="Challenge" />
                  About Us
                </a>
                <a className="link-card" href="/contact">
                  <img
                    className="logo-image"
                    src={contactLogo}
                    alt="Challenge"
                  />
                  Contact
                </a>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="text-container">
          <div>
            <a
              className="h2-black"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfnjS5GVH8lFSWF_shv7q1JpCoN_9ZCpIfsCCGrSqyF3P9Gyw/viewform?pli=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Register Here!
            </a>
          </div>
        </div> */}
      </div>
    );
  };

  return <Countdown date={targetDate} renderer={renderer} />;
};

export default Home;
