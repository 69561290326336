import React from "react";

const Calendar = () => {
  return (
    <div className="home">
      <div className="h2-black">
        <p>Upcoming Events</p>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>Office Hours - Wednesday 9/20 4:30-5:30pm</p>
        </div>
        <div className="text-container">
          <div>
            <a
              className="button-maroon-prev"
              href="https://asu.zoom.us/j/89403406837"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join Zoom
            </a>
          </div>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>Technical Writing - Wednesday 9/27 4:30-5:30pm</p>
        </div>
        <div className="text-container">
          <div>
            <a
              className="button-maroon-prev"
              href="https://asu.zoom.us/j/89403406837"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join Zoom
            </a>
          </div>
        </div>
      </div>
      <div className="text-container">
        <div className="h2-black">
          <p>Previous Events</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>
            In case you missed it, here are the recordings from our past events.
            Email us with any questions!​
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>8/31/23 Ideation and Brainstorming Webinar</p>
        </div>
        <div>
          <a
            className="button-maroon-prev"
            href="https://drive.google.com/file/d/11UWxTVWT-g4kU91ny0Ohr8QEBtzQ7s_x/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Recording
          </a>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p>9/13/23 What is a Proposal?</p>
        </div>
        <div>
          <a
            className="button-maroon-prev"
            href="https://drive.google.com/file/d/1418vEqPb66Gj-wNzJnPQFuFveoKy1inE/view?usp=drive_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Recording
          </a>
        </div>
      </div>
      <div className="text-container">
        <div className="h2-black">
          <p>Calendar</p>
        </div>
      </div>
      <div>
        <iframe
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FPhoenix&src=aWxsdW1pbmF0ZS5sdW1pbm9zaXR5QGdtYWlsLmNvbQ&src=ODM4N2JhMmY4NjJlYzU2N2I4Mjc2MWE5ZjcyYmI5ZjRlMjFhNWE5YzRhZGZlM2E5YzU3YjA3OTA2N2Q1NWM0YUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%23F09300&color=%230B8043"
          width="100%"
          height="600"
          title="Google Calendar"
        ></iframe>
      </div>
    </div>
  );
};

export default Calendar;
