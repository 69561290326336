import React from "react";
import "./style.css";
import NavigationBar from "./NavigationBar";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home";
import Challenge from "./challenge";
import Registration from "./registration";
import Submission from "./submission";
import Judges from "./judges";
import Past from "./past";
import About from "./about";
import Contact from "./contact";
import Calendar from "./calendar"; 

const App = () => {
  return (
    <div>
      <Router>
        <NavigationBar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/challenge" element={<Challenge />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/submission" element={<Submission />} />
            <Route path="/judges" element={<Judges />} />
            <Route path="/past" element={<Past />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        <footer>
          <p>Arizona State University</p>
        </footer>
      </Router>
    </div>
  );
};

export default App;
