import React from "react";

const Registration = () => {
  return (
    <div className="home">
      <div className="text-container">
        <div className="title-black">
          <p>Registration will be open on August 1st!</p>
        </div>
      </div>
      { /* <div className="text-container">
        <div className="h2-black">
          <p>Complete by</p>
        </div>
         <div className="h2-maroon">
          <p>10/06/20233</p>
        </div>
      </div>
      <div className="text-container">
        <div className="h2-black">
          <p>Proposal due </p>
        </div>
        <div className="h2-maroon">
          <p>10/20/20233</p>
        </div>
      </div>
      <div className="text-container">
        <div className="h2-black">
          <p>Criteria</p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-b1">
          <p> - Teams of 4 students or less </p>
          <p> - Everyone must be a high school student</p>
          <p>
            - Every team must have a mentor/supervisor (teacher, parent,
            administrator, etc.)
            <p>
              - Each team must have a community partner or industry
              professional.
            </p>
            - Must be registered by 10/06/2023
          </p>
          <p> - Must fill out all required forms</p>
        </div>
      </div>
      <div className="text-container">
        <div>
          <a
            className="button-maroon"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfnjS5GVH8lFSWF_shv7q1JpCoN_9ZCpIfsCCGrSqyF3P9Gyw/viewform?pli=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register
          </a>
          <a
            className="button-maroon"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeV20KjdbLiXGf3mfk6DYN9CJM0hLXdfJHgmErt_Y3prmrn_g/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            Required Forms
          </a>
        </div>
        <div className="text-container">
          <a
            className="button-white"
            href="https://docs.google.com/document/d/17LJkxry3omfnFw2yT2CJhrYmBbeRPYSWKInQC-aNs60/edit?usp=sharing "
            target="_blank"
            rel="noopener noreferrer"
          >
            Student Packet
          </a>
          <a
            className="button-white"
            href="https://docs.google.com/document/d/14MNRGB9kK8ZfGA8AwRBUdoeo940ZCORDFKjAKUT1Suc/edit?usp=drive_link "
            target="_blank"
            rel="noopener noreferrer"
          >
            Mentor Packet
          </a>
          <a
            className="button-white"
            href="https://drive.google.com/file/d/1XkcAVdbVejYGOGIBPRQLmdmfChPSjJeA/view?usp=share_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Student Form
          </a>
          <a
            className="button-white"
            href="https://drive.google.com/file/d/1maoD4vo-0OTthdFvj_Ha_DOK18aCkIgg/view?usp=share_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mentor Form
          </a>
            </div> 
      </div> */}
    </div>
  );
};

export default Registration;
