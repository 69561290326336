import React from "react";

const Challenge = () => {
  return (
    <div className="home">
      <div className="text-container">
        <div className="title-black">
          <p>Contact</p>
        </div>
      </div>

      <div className="text-container">
        <div className="h2-black">
          <p>Email</p>
        </div>
        <div className="text-b1">
        <p>
          illuminate.luminosity@gmail.com
        </p>
      </div>
      </div>
      <div className="text-container">
        <div className="h2-black">
          <p>Instagram</p>
        </div>
        <div className="text-b1">
          <p>
            <a
              href="https://www.instagram.com/illuminatechallenge/?utm_source=ig_embed&amp;utm_campaign=loading"
              target="_blank"
              rel="noopener noreferrer"
            >
              @illuminatechallenge
            </a>
          </p>
        </div>
      </div>
      {/* <div className="text-container">
        <div className="h2-black">
          <p>Office Hours:</p>
        </div>
      </div>
      <div className="text-b1">
        <p>
          Need some extra help? An Illuminate team member will be available to
          help with any questions about the competition or offer advice on
          deliverables.
        </p>
      </div>
      <div className="text-b1">
        <p>
          Below are the Zoom link and our office hours. Zoom:  https://asu.zoom.us/j/89403406837
        </p>
        <p>September 6th: 4:30pm - 5:30pm MST</p>
        <p>September 20th: 4:30pm - 5:30pm MST</p>
        <p>October 4th: 4:30pm - 5:30pm MST</p>
        <p>October 18th: 4:30pm - 5:30pm MST</p>
      </div>  */}
      <div className="spacer"></div>
    </div>
  );
};

export default Challenge;
