import { useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from 'hamburger-react'
import logo from ".//Illuminate Web Assets/Header/asuIlluminate.png";

const NavigationBar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Logo 1" />
        </Link>
      </div>
      <div className="menu-icon" onClick={handleShowNavbar}>
        <Hamburger style={{ color: 'white' }} />
      </div>
      <div className={`nav-elements  ${showNavbar && "active"}`}>
        <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/challenge">The Challenge</Link>
            </li>
            <li>
              <Link to="/calendar">Calendar</Link>
            </li>
            <li>
              <Link to="/registration">Registration</Link>
            </li>
            {/* <li>
              <Link to="/submission">Submission</Link>
            </li>
            <li>
              <Link to="/judges">Judges</Link>
            </li> */}
            <li>
              <Link to="/past">Past Winners</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
    </nav>
  );
};

export default NavigationBar;
